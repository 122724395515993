import React from "react"
import 'uikit/dist/css/uikit.css'
import Layout from "../layouts"
import { coldCall, covid19 } from "../utils/generalInformation";

import SimpleParagraph from "../components/simple-paragraph/simple-paragraph";


// markup
const ColdCall = () => {


  return (
    <Layout
      SEOTitle={"Cold call warning - United Legal Assistance"}
      SEODescription={"Cold call warning - United Legal Assistance"}
      footerDisclaimer={""}>

      <SimpleParagraph props={coldCall} />

    </Layout>
  )

}

export default ColdCall